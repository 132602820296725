<template>
  <div class="content">
    <img style="width: 100%"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-1.jpeg?imageView2/interlace/1" alt="" />
    <div class="video-container">
      <video ref="videoRef" @play="isPlaying = true" @ended="
        $refs.videoRef.controls = false;
      isPlaying = false;
      $refs.videoRef.currentTime = 0;
      " src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道校招采访.mp4" muted preload></video>
      <img v-show="!isPlaying" class="video-button" @click="
        $refs.videoRef.controls = true;
      $refs.videoRef.play();
      " src="@/assets/img/mobile-video-button.png" />
    </div>
    <img style="width: 100%"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-2.jpeg?imageView2/interlace/1" alt="" />
    <div class="directions">
      <img style="width: 100%"
        src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-3.jpeg?imageView2/interlace/1" alt="" />
      <div class="directions-active">
        <div v-for="(item, index) in 2" :key="item" :class="`direction-${item}`"
          @click="jump(recruitPositions[index].deliverUrl)"></div>
      </div>
    </div>
    <img style="width: 100%"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-6.jpeg?imageView2/interlace/1" alt="" />
    <van-swipe :autoplay="3000" class="swiper" indicator-color="#000">
      <van-swipe-item v-for="(image, index) in swpierList" :key="index">
        <div class="swipe-item">
          <img v-lazy="image" />
        </div>
      </van-swipe-item>
    </van-swipe>
    <img style="width: 100%"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-4.jpeg?imageView2/interlace/1" alt="" />
    <img style="width: 100%"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘-手机端-5.jpeg?imageView2/interlace/1" alt="" @click="
        jump(
          'https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755'
        )
        " />
  </div>
</template>
<script>
import mobileBanner from "./homeComponents/mobileBanner.vue";
import { onvoRecruitPositions, onvoSwipeList } from "@/utils/config.js";
export default {
  components: {
    mobileBanner,
  },
  data() {
    return {
      isPlaying: false,
      recruitPositions: onvoRecruitPositions,
      swpierList: onvoSwipeList,
    };
  },
  methods: {
    jump(url) {
      window.open(url, "_self");
    },
  },
};
</script>
<style scoped>
.content {
  padding-bottom: min(700px, 50vh);
}

.video-button {
  width: max(20%, 50px);
  height: max(20%, 50px);
}
</style>
<style scoped lang="less">
.content {
  width: 100%;
  max-width: 750px;
  margin: 0px auto;
  background-color: #ffefe0;

  .button {
    position: absolute;
    bottom: 250px;
    left: 150px;
    width: 450px;
    height: 150px;
    cursor: pointer;
  }
}

.video {
  width: 100%;
  display: block;
}

.directions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .directions-active {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &>* {
      width: 50%;
      height: 100%;
      cursor: pointer;
    }
  }
}

.video-container {
  width: 90%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;

  video {
    display: block;
    width: 100%;
    height: 100%;
  }

  .video-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
}

.swiper {
  width: 100%;
}

/deep/ .van-swipe__indicator {
  background-color: #666;
}

.swipe-item {
  width: 100%;
  height: 450px;

  &>img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
